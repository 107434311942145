import { render, staticRenderFns } from "./registration-lead.vue?vue&type=template&id=2908b571&scoped=true&"
import script from "./registration-lead.vue?vue&type=script&lang=js&"
export * from "./registration-lead.vue?vue&type=script&lang=js&"
import style0 from "./registration-lead.vue?vue&type=style&index=0&id=2908b571&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2908b571",
  null
  
)

export default component.exports